<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'responsable-commercial' ||
        getUser.role.slug === 'community-manager'
    "
  >
  <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/blog">Blog</b-breadcrumb-item>
      <b-breadcrumb-item active>Détails</b-breadcrumb-item>
    </b-breadcrumb>
    <div
      v-if="isPageLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <!-- content -->
    <div
      v-else
      class="blog-detail-wrapper"
    >
      <b-card
        no-body
        class="mt-2"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- New article -->
            <b-col
              cols="12"
              md="3"
              sm="6"
            >
              <b-button
                
                variant="info"
                @click="applyPublishArticleAction(blogDetail.id)"
              >
                Publié l'article
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="3"
              sm="6"
            >

              <b-button
                :disabled="
                  blogDetail.status == 1 || blogDetail.status == 2 || getUser.role.slug === 'community-manager'
                "
                variant="success"
                @click="applyValidateArticleAction(blogDetail.id)"
              >Validé l'article
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="3"
              sm="6"
            >
              <b-button
                variant="outline-warning"
                @click="applyUpdateArticle(blogDetail)"
              >
                Modifié l'article
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="3"
              sm="6"
            >
              <b-button
                v-b-modal.modal-reject-article
                variant="outline-danger"
                :disabled="blogDetail.status == 1 || blogDetail.status == 2 || blogDetail.status == 3 || getUser.role.slug === 'community-manager'"
              >
                Rejeté l'article
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="3"
              sm="6"
              class="mt-2"
            >
              <b-button
                variant="danger"
                :disabled="getUser.role.slug === 'community-manager'"
                @click="applyDeleteArticleAction(blogDetail.id)"
              >Supprimé l'article
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
            class="card-img-top"
            :img-src="blogDetail.image"
            img-top
            img-alt="Blog Detail Pic"
            :title="blogDetail.title"
            :sub-title="blogDetail.category.name"
            tag="article"
          >
            <b-media
              no-body
              class="mb-5"
            >
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  v-if="blogDetail.author"
                  size="40"
                  variant="light-primary"
                  badge
                  :text="
                    avatarText(
                      `${blogDetail.author.first_name} ` +
                        ` ${blogDetail.author.last_name}`
                    )
                  "
                  :src="blogDetail.author.profile_image"
                  class="badge-minimal"
                  badge-variant="success"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">Rédigé par</small>
                <small>
                  <b-link class="text-body">{{
                    blogDetail.author
                      ? `${blogDetail.author.last_name}-${blogDetail.author.first_name}`
                      : "----"
                  }}
                  </b-link>
                </small>
                <br>
                <small
                  v-if="blogDetail.status == 0"
                  class="text-muted"
                >Crée le
                  {{
                    moment(blogDetail.created_at).locale("fr").format("llll")
                  }}</small>
                <small
                  v-else-if="blogDetail.status == 1"
                  class="text-muted"
                >Validé le
                  {{
                    moment(blogDetail.validation_date)
                      .locale("fr")
                      .format("llll")
                  }}</small>
                <small
                  v-else-if="blogDetail.status == 2"
                  class="text-muted"
                >Publié le
                  {{
                    moment(blogDetail.published_date)
                      .locale("fr")
                      .format("llll")
                  }}</small>
              </b-media-body>
              <div class="d-flex align-items-center justify-content-end mt-n3">
                <b-link>
                  <b-badge
                    pill
                    class=""
                    :variant="resolveArticleStatusVariant(blogDetail.status)"
                  >
                    {{ resolveArticleStatusText(blogDetail.status) }}
                  </b-badge>
                </b-link>
              </div>
              <br>
            </b-media>
            <!-- eslint-disable vue/no-v-html -->
            <b-row class="d-flex align-items-center justify-content-center">
              <div
                class="blog-content-truncate"
                v-html="blogDetail.content"
              />
            </b-row>

            <!-- user commnets -->

            <!-- eslint-enable -->
          </b-card>
        </b-col>
        <!--/ blogs -->

        <!-- blog comment -->

        <!--/ blog comment -->

        <!-- Leave a Blog Comment -->

        <!--/ Leave a Blog Comment -->
      </b-row>
      <!--/ blogs -->
    </div>

    <b-modal
      id="modal-reject-article"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Rejet de l'article"
    >
      <validation-observer
        #default="{}"
        ref="rejectArticle"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyRejectArticleAction"
        >
          <validation-provider
            #default="{ errors }"
            name="Raison de rejet"
            rules="required"
          >
            <b-form-group
              label="Raison de rejet de l'article"
              label-for="reason"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="reason"
                v-model="payload.rejection_reason"
                rows="3"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isRejectArticleLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isRejectArticleLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Soumettre</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BModal,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BAvatar,
    BMediaAside,
    BModal,
    BMediaBody,
    BLink,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    ToastificationContent,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      isPageLoading: false,
      isRejectArticleLoading: false,
      avatarText,
      blogDetail: {},
      payload: {
        rejection_reason: '',
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('blog', ['blogArticle', 'blogsArticles']),
  },
  created() {
    if (this.blogArticle !== null) {
      this.blogDetail = this.blogArticle
    } else {
      const id = this.$route.params.id.split('-').pop()
      this.appyGetBlogArticleDetail(id)
    }
  },
  methods: {
    ...mapActions('blog', [
      'getBlogArticleDetail',
      'validateBlogArticleAction',
      'deleteBlogArticleAction',
      'rejectBlogArticleAction',
      'publishBlogArticleAction',
    ]),
    applyUpdateArticle() {
      this.$router.push({ name: 'blog-update', params: this.$route.params.id })
    },
    hideModal() {
      this.$bvModal.hide('modal-reject-article')
      this.payload.rejection_reason = ''
    },

    applyRejectArticleAction() {
      this.$refs.rejectArticle.validate().then(success => {
        if (success) {
          this.isRejectArticleLoading = true
          this.rejectBlogArticleAction({
            payload: this.payload,
            id: this.blogDetail.id,
          })
            .then(response => {
              this.blogDetail = this.blogArticle
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModal()
              this.isRejectArticleLoading = false
            })
            .catch(error => {
              this.isRejectArticleLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: `${error.response.data.message}`,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },
    applyPublishArticleAction(id) {
      this.$swal({
        title: 'Publication',
        text: 'Êtes-vous sûr de vouloir publier cet article ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.publishBlogArticleAction(id).then(() => { })
            .catch(error => {
              this.$swal.showValidationMessage(
                `${error.response.data.message}`,
              )
            })
        },
      })
        .then(response => {
          if (response.isConfirmed) {
            this.blogDetail = this.blogArticle
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Article publié avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyValidateArticleAction(id) {
      this.$swal({
        title: 'Validation',
        text: 'Êtes-vous sûr de vouloir valider cet article ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.validateBlogArticleAction(id)
        },
      })
        .then(response => {
          if (response.isConfirmed) {
            this.blogDetail = this.blogArticle
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Article validé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyDeleteArticleAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cet article ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteBlogArticleAction(id)
        },
      })
        .then(response => {
          if (response.isConfirmed) {
            this.blogDetail = this.blogArticle
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Article supprimé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            console.log('res', this.blogsArticles)
            this.$router.push({ name: 'blog' })
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    appyGetBlogArticleDetail(id) {
      this.isPageLoading = true
      this.getBlogArticleDetail(id)
        .then(response => {
          this.blogDetail = response.data
          this.isPageLoading = false
        })
        .catch(error => {
          this.isPageLoading = false
        })
    },
    resolveArticleStatusVariant: status => {
      if (status === 1) return 'info'
      if (status === 0) return 'danger'
      if (status === 2) return 'success'
      if (status === 3) return 'warning'
    },
    resolveArticleStatusText: status => {
      if (status === 1) return 'Validé'
      if (status === 0) return 'Non validé'
      if (status === 2) return 'Publié'
      if (status === 3) return 'Rejeté'
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style scoped>
.card-img-top {
  object-fit: cover !important;
  height: 350px;
}
</style>
